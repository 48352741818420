
<template>
  <div class="video">
    <img class="photo" :src="cover" />
    <div class="vdbg">
      <video-player
        class="video-player-box vjs-big-play-centered"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="playsinline"
        customEventName="customstatechangedeventname"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
      />
      <div class="videoTitle">{{ title }}</div>
    </div>
    <div class="loading" v-show="loading">
      <van-loading color="#c51a04" size="34px">请求数据中...</van-loading>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import { Loading } from "vant";

export default {
  name: "Video",
  props: {
    videoUrl: Object,
  },
  data() {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src:
              "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm",
          },
        ],
        poster: "http://localhost/547be638615da10.png",
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      loading: true,
      cover: "",
      objectId: "",
      title: "",
      videoButton: require("../../src/assets/logo.png"),
    };
  },
  components: {
    videoPlayer,
    "van-loading": Loading,
  },
  mounted() {
    if (this.$route.query.id != undefined) {
      this.objectId = this.$route.query.id;
    } else if (this.$route.params.id != undefined) {
      this.objectId = this.$route.params.id;
    }

    if (this.objectId == undefined || this.objectId == "") {
      this.objectId = "UpqTEEEd";
    }
    this.fetchVideoInfo();
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    playsinline() {
      var ua = navigator.userAgent.toLocaleLowerCase();
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    clickStartButton: function () {
      this.$refs.videoPlayer.player.play();
    },
    onPlayerPlay(player) {
      this.videoPlayState = true;
    },
    onPlayerPause(player) {
      this.videoPlayState = false;
    },
    fetchVideoInfo() {
      this.loading = true;
      const query = Bmob.Query("HmVideo");
      query
        .get(this.objectId)
        .then((res) => {
          this.loading = false;
          console.log(res);

          this.playerOptions.sources[0].src = res.url;
          this.cover = res.coverImage;
          this.title = res.title;

          videoPlayer.play();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="stylus">
.video {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.video-player-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}

.mask {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.vdbg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
}

.videoTitle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  margin: 20px;
}

.video-js .vjs-big-play-button {
  /*
   播放按钮换成圆形
  */
  height: 2em;
  width: 2em;
  line-height: 2em;
  border-radius: 1em;
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(250, 245, 235, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>